const TasksDefinitions = {
	'REBOOT': {
		'label': 'restart urządzenia',
	},
	'UPDATE_DOCKER': {
		'label': 'aktualizacja urządzenia',
	},
	'RELOAD_PROPERTIES': {
		'label': 'przeładuj ustawienia',
	},
	'REFRESH_PAGE': {
		'label': 'odśwież stronę kiosku',
	},
};


export default TasksDefinitions;
