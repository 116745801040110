const RolesDefinitions = {
	"ROLE_USERS": {
		label: 'Zarządzanie użytkownikami',
	},
	"ROLE_DEVICES": {
		label: "Dostęp do urządzeń",
	},
	"ROLE_DEVICES_ACTIONS": {
		label: "Operacje na urządzeniach",
	},
	"ROLE_SETTINGS": {
		label: "Dostęp do ustawień",
	},
	"ROLE_TENANT": {
		label: "Zarządzanie dzierżawą",
	},
	"ROLE_AVAILABILITY": {
		label: "Dostęp do niedostępności urządzeń",
	},
	"ROLE_AVAILABILITY_ACKNOWLEDGE": {
		label: "Potwierdzanie niedostępności urządzenia",
	}
}

export default RolesDefinitions