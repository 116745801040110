import React, { useState } from "react";
import { Form, Button, Alert, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { NotificationManager } from "react-notifications";

const ChangePassword = () => {
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [loading, setLoading] = useState(false);

	const handleChangePassword = async (e) => {
		e.preventDefault();

		if (newPassword !== confirmPassword) {
			setErrorMessage("New password and confirmation do not match");
			return;
		}

		setLoading(true);

		try {
			// Wysyłanie żądania do endpointa zmiany hasła
			const response = await axios.post("/users/me/change-password", {
				oldPassword: oldPassword,
				newPassword: newPassword,
			});

			setOldPassword("");
			setNewPassword("");
			setConfirmPassword("");
			NotificationManager.success("Hasło zostało zmienione");
		} catch (error) {
			NotificationManager.error("Błąd podczas zmiany hasła");
		} finally {
			setLoading(false);
		}
	};

	return (
		<Container className='mt-5'>
			<Row>
				<Col md={6}>
					<h2>Zmiana hasła</h2>

					<Form onSubmit={handleChangePassword}>
						<Form.Group
							controlId='oldPassword'
							className='mb-3'
						>
							<Form.Label>Stare hasło</Form.Label>
							<Form.Control
								type='password'
								placeholder='wprowadź stare hasło'
								value={oldPassword}
								onChange={(e) => setOldPassword(e.target.value)}
								required
							/>
						</Form.Group>

						<Form.Group
							controlId='newPassword'
							className='mb-3'
						>
							<Form.Label>Nowe hasło</Form.Label>
							<Form.Control
								type='password'
								placeholder='wprowadź nowe hasło'
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								required
							/>
						</Form.Group>

						<Form.Group
							controlId='confirmPassword'
							className='mb-3'
						>
							<Form.Label>Powtórz nowe hasło</Form.Label>
							<Form.Control
								type='password'
								placeholder='ponownie wprowadź nowe hasło'
								value={confirmPassword}
								onChange={(e) =>
									setConfirmPassword(e.target.value)
								}
								required
							/>
						</Form.Group>

						<Button
							variant='primary'
							type='submit'
							disabled={loading}
						>
							{loading ? "Trwa zmiana hasła..." : "Zmień hasło"}
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default ChangePassword;
