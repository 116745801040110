import React, { useEffect, useState } from "react"
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import Properties from "./Properties"
import { Edit as PropertyEdit } from "../Common/Properties/Edit"
import { TenantInfo } from "./TenantInfo"
import { useSelector } from "react-redux"




export default () => {
	const store = useSelector((store) => store.app)

	return <Container>
		<Row>
			<Col>
				<h1>Ustawienia</h1>
			</Col>
		</Row>
		<Row>
			<Col>
				<Tabs
					defaultActiveKey="notification_settings"
					id="uncontrolled-tab-example"
					className="mb-3"
				>
					{/*
					<Tab eventKey="home" title="Zmienne">
						<Properties />
					</Tab>
*/}
					<Tab eventKey="notification_settings" title="Ustawienia powiadomień">
						<PropertyEdit deviceId="GLOBAL" name="SYSTEM_NOTIFICATION_EMAILS" label="Adresy e-mail do notyfikacji (po jednym w każdej linii)" type="textarea" />
						{/*
						<PropertyEdit deviceId="GLOBAL" name="SYSTEM_NOTIFICATION_PHONES" label="Numery telefonu do notyfikacji (po jednym w każdej linii)" type="textarea" />
*/}
						<PropertyEdit deviceId="GLOBAL" name="SYSTEM_NOTIFICATION_TIMEOUT_LIMIT" label="Powiadamiaj, gdy niedostępne przez (minut)" />
						<PropertyEdit deviceId="GLOBAL" name="SYSTEM_NOTIFICATION_REPEAT" label="Ponawiaj powiadomienie co (minut, 0 - wyłączone)" />
					</Tab>
{/*					<Tab eventKey="watchdog_settings" title="Ustawienia Watchdoga">
						<PropertyEdit deviceId="GLOBAL" name="WATCHDOG_ENABLED" label="Czy watchdog jest włączony?" type="checkbox" />
						<PropertyEdit deviceId="GLOBAL" name="WATCHDOG_NAME" label="Uniwersalna nazwa wywołania Watchdoga" />
						<PropertyEdit deviceId="GLOBAL" name="WATCHDOG_TIMEOUT" label="Maksymalny odstęp miedzy karmieniem Watchdoga" />
						<PropertyEdit deviceId="GLOBAL" name="WATCHDOG_RECOVERY_PROCEDURE" label="Procedura automatycznej naprawy" />
					</Tab>
*/}					
					<Tab eventKey="internal_watchdog_settings" title="Ustawienia wew. Watchdog">
						<PropertyEdit deviceId="GLOBAL" name="INTERNAL_WATCHDOG_ENABLED" label="Czy wewnętrznych watchdog jest włączony?" type="checkbox" />
						<PropertyEdit deviceId="GLOBAL" name="INTERNAL_WATCHDOG_HOST" label="Testowany adres" />
					</Tab>
					{/*
					<Tab eventKey="smtp_settings" title="Ustawienia SMTP">
						<PropertyEdit deviceId="GLOBAL" name="SYSTEM_SMTP_TENANT_SETTINGS" label="Korzystaj z własnego serwera" type="checkbox" />
						<PropertyEdit deviceId="GLOBAL" name="SYSTEM_SMTP_SERVER" label="Adres serwera" />
						<PropertyEdit deviceId="GLOBAL" name="SYSTEM_SMTP_PORT" label="Port SMTP" />
						<PropertyEdit deviceId="GLOBAL" name="SYSTEM_SMTP_USERNAME" label="Nazwa użytkownika" />
						<PropertyEdit deviceId="GLOBAL" name="SYSTEM_SMTP_PASSWORD" label="Hasło" type="password" />
					</Tab>
*/}
					<Tab eventKey="device_settins" title="Ustawienia urządzeń">
						<PropertyEdit deviceId="GLOBAL" name="KIOSK_URL" label="Adres HTTP dla urządzeń" allowEditName={true} />
						<PropertyEdit deviceId="GLOBAL" name="CHROME_DEVICE_SCALE_FACTOR" label="Skalowanie obrazu" />
						<PropertyEdit deviceId="GLOBAL" name="DNAT_TRANSLATIONS" label="Translacje adresów IP" />

						{store?.user?.roles.includes('ROLE_VENDOR_INFO') && <>
							<PropertyEdit deviceId="GLOBAL" name="SSH_ENABLED" label="Włącz SSH" type="checkbox" />
						</>}

						<PropertyEdit deviceId="GLOBAL" name="WLAN_ENABLED" label="Włącz Wi-Fi klienta" type="checkbox" />
						<PropertyEdit deviceId="GLOBAL" name="WLAN_SSID" label="SSID sieci Wi-Fi" />
						<PropertyEdit deviceId="GLOBAL" name="WLAN_PASSWORD" label="Hasło sieci Wi-Fi" type="password" />
					</Tab>

					{store?.user?.roles.includes('ROLE_') &&
						<Tab eventKey="tenant" title="Dzierżawa">
							<TenantInfo />
						</Tab>
					}
				</Tabs>
			</Col>
		</Row>
	</Container>
}
