import React, { useState, useEffect } from 'react'
import { Table, Button, Modal, Form, Container, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import PasswordStrengthBar from 'react-password-strength-bar'
import { NotificationManager } from 'react-notifications'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RolesDefinitions from '../Common/RolesDefinitions'

function UserList() {
	const [users, setUsers] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [editUserId, setEditUserId] = useState(null)
	const [fullName, setFullName] = useState('')
	const [userEmail, setUserEmail] = useState('')
	const [userPassword, setUserPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [userRoles, setUserRoles] = useState([])
	const [isPasswordStrongEnough, setIsPasswordStrongEnough] = useState(false)
	const [isPasswordMatch, setIsPasswordMatch] = useState(true)
	const [isLoading, setIsLoading] = useState(false)

	const reload = () => {
		setIsLoading(true)
		axios.get('/users')
			.then(response => {
				setUsers(response.data)
			})
			.catch(error => {
				console.error('Błąd podczas pobierania użytkowników:', error)
			})
			.finally(() => setIsLoading(false))
	}


	useEffect(() => {
		reload()
	}, [])

	const validateForm = () => {
		return (
			userEmail !== '' &&
			fullName !== '' &&
			((isPasswordStrongEnough &&
				isPasswordMatch) || (userPassword == '' && editUserId !== null))
		)
	}

	const handleDeleteUser = (id) => {
		const confirmDelete = window.confirm("Czy na pewno chcesz usunąć tego użytkownika?")
		if (confirmDelete) {
			axios.delete(`/users/${id}`)
				.then(response => {
					setUsers(users.filter(user => user.id !== id))
				})
				.catch(error => {
					console.error('Błąd podczas usuwania użytkownika:', error)
				})
		}
	}

	const handleEditUser = (id) => {
		const userToEdit = users.find(user => user.id === id)
		setEditUserId(id)
		setFullName(userToEdit.name)
		setUserEmail(userToEdit.email)
		setUserRoles(userToEdit.roles)
		setShowModal(true)
		setUserPassword('')
		setConfirmPassword('')
	}

	const handleSaveUser = () => {
		if (editUserId) {
			const data = {
				name: fullName,
				email: userEmail,
				roles: userRoles
			}
			if (userPassword !== '')
				data.newPassword = userPassword
			
			axios.patch(`/users/${editUserId}`, data)
				.then(response => {
					setUsers(users.map(user => {
						if (user.id === editUserId) {
							return {
								...user,
								name: fullName,
								email: userEmail,
								roles: userRoles
							}
						}
						return user
					}))
					setShowModal(false)
				})
				.catch(error => {
					console.error('Błąd podczas edycji użytkownika:', error)
				})
		} else {
			axios.post('/users', {
				name: fullName,
				email: userEmail,
				newPassword: userPassword,
				roles: userRoles
			})
				.then(response => {
					setUsers([...users, response.data])
					setShowModal(false)
				})
				.catch(error => {
					console.log(error)
					NotificationManager.error(
						error?.response?.data?.detail,
						"Błąd dodawania użytkownika",
						3000
					)
				})
		}
	}


	return (
		<Container>
			<Row className="my-2">
				<Col>
					<h1>Użytkownicy
						<Button variant="secondary" onClick={reload} disabled={isLoading} className="mx-3">
							<FontAwesomeIcon icon={faSync} spin={isLoading} />
						</Button>


					</h1>
				</Col>
				<Col>
					<Button variant="success" onClick={() => {
						setEditUserId(null)
						setFullName('')
						setUserEmail('')
						setUserPassword('')
						setConfirmPassword('')
						setUserRoles([])
						setIsPasswordStrongEnough(false)
						setShowModal(true)
					}} style={{ float: 'right' }}>Dodaj użytkownika</Button>
				</Col>
			</Row>
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Email</th>
						<th>Imię i nazwisko</th>
						<th>Uprawnienia</th>
						<th>Akcje</th>
					</tr>
				</thead>
				<tbody>
					{users.map(user => (
						<tr key={user.id}>
							<td>{user.email}</td>
							<td>{user.name}</td>
							<td>
								{user.roles.map(role => <>{RolesDefinitions?.[role]?.label} {RolesDefinitions?.[role]?.label && <br />}</>)}
							</td>
							<td>
								<Button variant="info" onClick={() => handleEditUser(user.id)} style={{ marginRight: '0.5rem' }}>Edytuj</Button>
								<Button variant="danger" onClick={() => handleDeleteUser(user.id)}>Usuń</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>

			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{editUserId ? 'Edytuj użytkownika' : 'Dodaj użytkownika'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="formBasicFullName">
							<Form.Label>Imię i nazwisko</Form.Label>
							<Form.Control
								type="text"
								value={fullName}
								onChange={(e) => setFullName(e.target.value)}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="email"
								value={userEmail}
								onChange={(e) => setUserEmail(e.target.value)}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicPassword">
							<Form.Label>Hasło</Form.Label>
							<Form.Control
								type="password"
								value={userPassword}
								onChange={(e) => {
									setUserPassword(e.target.value)
									setIsPasswordStrongEnough(e.target.value.length >= 10)
									setIsPasswordMatch(e.target.value === confirmPassword)
								}}
							/>
							<PasswordStrengthBar
								password={userPassword}
								onChangeScore={score => setIsPasswordStrongEnough(score >= 3)}
								scoreWords={['bardzo słabe', 'słabe', 'średnie', 'dobre', 'bardzo dobre']}
								shortScoreWord="za krótkie"
							/>
						</Form.Group>
						<Form.Group controlId="formBasicConfirmPassword">
							<Form.Label>Powtórz hasło</Form.Label>
							<Form.Control
								type="password"
								value={confirmPassword}
								onChange={(e) => {
									setConfirmPassword(e.target.value)
									setIsPasswordMatch(e.target.value === userPassword)
								}}
							/>
							{confirmPassword !== '' && !isPasswordMatch && <Form.Text className="text-danger">Hasła nie są identyczne</Form.Text>}
						</Form.Group>
						<Form.Group controlId="formBasicRoles">
							<Form.Label>Uprawnienia</Form.Label>
							{Object.keys(RolesDefinitions).map((role) => (
								<Form.Check
									id={"role-" + role}
									type="checkbox"
									label={RolesDefinitions[role].label}
									checked={userRoles.includes(role)}
									onChange={() => {
										setUserRoles(prevRoles => {
											if (prevRoles.includes(role)) {
												return prevRoles.filter(_role => _role !== role)
											} else {
												return [...prevRoles, role]
											}
										})
									}}
								/>
							))}
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowModal(false)}>
						Zamknij
					</Button>
					<Button variant="primary" onClick={handleSaveUser} disabled={!validateForm()}>
						{editUserId ? 'Zapisz zmiany' : 'Dodaj użytkownika'}
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	)
}

export default UserList
